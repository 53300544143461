import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { media, fonts } from 'src/views/theme';

import SimpleCard from '@shipae/components-sandbox/component/simple-card';

import {
  SimpleCardListWidget as IProps,
} from 'infrastructure/gatsby/types/simple-card-list';
import SmartLink from 'components/smart-link';
import gloabalReactMdOptions from '../shared/react-makdown-options';

const Wrapper = styled.section`
  padding: 12rem 0;
  overflow: hidden;

  ${ media.sm } {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
`;

const TextCard = styled.div`
  display: flex;
  align-items: center;
  width: 32.8rem;

  & h2 {
    text-transform: uppercase;
    ${ fonts.displaym200 }
  }
`;

const TextContainer = styled.div`
`;

const CardContainer = styled.div`
  margin-left: 3.2rem;
`;

const SimpleCardList: React.FC<IProps> = ({
  sectionId,
  text,
  cards,
}) => (
  <Wrapper id={sectionId}>
    <Container>
      <TextCard>
        <TextContainer>
          <ReactMarkdown options={gloabalReactMdOptions}>
            {text || ''}
          </ReactMarkdown>
        </TextContainer>
      </TextCard>
      {cards.map((card) => (
        <CardContainer key={card.id}>
          <SimpleCard
            text={card.title || 'No text'}
            linkLabel={card.link?.label || 'No label'}
            linkTo={card.link?.url || '#'}
            linkComponent={SmartLink}
          >
            <GatsbyImage
              fixed={card.image?.localFile?.childImageSharp?.fixed || []}
              alt={card.image?.alternativeText || ''}
            />
          </SimpleCard>
        </CardContainer>
      ))}
    </Container>
  </Wrapper>
);

export default SimpleCardList;
